<template>
  <div class="container">


    <div class="frequently-asked-question mt-4 mb-4">
      <h4 class="text-center">Часто задаваемые вопросы</h4>

      <div class="tabs">
        <TabView>
          <TabPanel header="Общие вопросы">
<!--            <Accordion>-->
<!--              <AccordionTab header="Как создать тестовый экзамен?">-->
<!--                <YouTube id="video1"-->
<!--                    src="https://www.youtube.com/watch?v=JqWeF83tbKk"-->
<!--                    ref="youtube" />-->
<!--              </AccordionTab>-->
<!--              <AccordionTab header="Как создать письменный экзамен?">-->
<!--                <YouTube id="video2"-->
<!--                    src="https://www.youtube.com/watch?v=QohuIeys4dI"-->
<!--                    ref="youtube" />-->
<!--              </AccordionTab>-->
<!--              -->
<!--            </Accordion>-->

            Список видео инструкций:
            <br>
            <ul>
              <li>
                Как создать тестовый экзамен?
                <br>
                <a href="https://www.youtube.com/watch?v=JqWeF83tbKk" target="_blank" @click="saveFaqLog('https://www.youtube.com/watch?v=JqWeF83tbKk')">Перейти</a>
              </li>
              <li>Как создать письменный экзамен?
                <br>
                <a href="https://www.youtube.com/watch?v=QohuIeys4dI" target="_blank" @click="saveFaqLog('https://www.youtube.com/watch?v=QohuIeys4dI')">Перейти</a>
              </li>

              <li>Добавление вопросов в Moodle используя программу Word 2003
                <br>
                <a href="https://uibkz-my.sharepoint.com/:f:/g/personal/cit_adm2_uib_kz/EuJq--PjBBFNqTn4knMqO40Bck2BmD7lUwml_KopSyaj7g?e=fJB0Ke" target="_blank"
                @click="saveFaqLog('https://uibkz-my.sharepoint.com/:f:/g/personal/cit_adm2_uib_kz/EuJq--PjBBFNqTn4knMqO40Bck2BmD7lUwml_KopSyaj7g?e=fJB0Ke')">
                  Перейти
                </a>
              </li>
              <li>
                Как импортировать вопросы новой системы moodle?
                <br>
                <a href="https://www.youtube.com/watch?v=ime42OjjIVE" target="_blank" @click="saveFaqLog('https://www.youtube.com/watch?v=ime42OjjIVE')">Перейти</a>
              </li>
              <li>
                Как импортировать банк вопросов из oldmoodle?
                <br>
                <a href="https://www.youtube.com/watch?v=K6_rEX5No6E" target="_blank" @click="saveFaqLog('https://www.youtube.com/watch?v=K6_rEX5No6E')">Перейти</a>
              </li>
              <li>
                Как выставлять оценки за экзамен (устно, take home, проект)?
                <br>
                <a href="https://www.youtube.com/watch?v=5xsNQ2lUicQ" target="_blank" @click="saveFaqLog('https://www.youtube.com/watch?v=5xsNQ2lUicQ')">Перейти</a>
              </li>
              <li>
                Работа с front.uib.kz
                <br>
                <a href="https://youtu.be/-ksKPXvSidI?si=DJ-SoZefdiguSlrt" target="_blank" @click="saveFaqLog('https://youtu.be/-ksKPXvSidI?si=DJ-SoZefdiguSlrt')">Перейти</a>
              </li>
              <li>
                Работа с moodle.uib.kz
                <br>
                <a href="https://youtu.be/ZwXNBwJPSfA?si=0l0DU2Duo0HBL3jc" target="_blank" @click="saveFaqLog('https://youtu.be/ZwXNBwJPSfA?si=0l0DU2Duo0HBL3jc')">Перейти</a>
              </li>
              <li>
                Критерии и варианты оценивания
                <br>
                <a href="https://youtu.be/V7sohHHQhwQ" target="_blank" @click="saveFaqLog('https://youtu.be/V7sohHHQhwQ')">Перейти</a>
              </li>
            </ul>



          </TabPanel>
          <TabPanel header="Экзамен">
            Экзамен
          </TabPanel>
          <TabPanel header="Moodle">
            Moodle
          </TabPanel>
        </TabView>
      </div>



    </div>

  </div>


</template>

<script>

import httpClient from "../services/http.service";
import {getCookie} from "@/utils/helpers/cookies.helpers";
export default {
  name: "FrequentlyAskedQuestion",
  data() {
    return {

    }
  },
  computed: {

  },

  methods: {
    saveFaqLog(videoUrl) {
      console.log(videoUrl, 'videoUrl')
      this.POST_FAQ_LOG(videoUrl)
    },

    async POST_FAQ_LOG(videoUrl) {
      try {
        let url = 'api/faq-log/create' + '?access-token=' + getCookie('ACCESS_TOKEN') + '&url='+ videoUrl

        const {
          status,
          data
        } = await httpClient.post(url);
        if (status === 200) {
          console.log(data, 'saved')
        }
      } catch (e) {
        console.log(e.response);
        return {
          success: false,
          error: e.response.data.errors
        }
      }
    },

  },

  async mounted() {

  }
}
</script>

<style scoped>

</style>